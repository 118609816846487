<template>
  <div
    class="m-takt-status"
  >
    <v-toolbar
      dense
      elevation="0"
      color="backgroundAccent"
    >
      <div
        class="full-width d-flex justify-space-around align-center ml-n3"
      >
        <!-- now -->
        <div
          class="d-flex align-center"
          :style="{
            minWidth: '120px',
            opacity: $wait.is('initial load - todos by teams') ? 0 : 1,
          }"
        >
          <div
            class="mr-4"
          >
            <div
              v-translate
              translate-context="MTaktStatus view toolbar content"
              class="text-uppercase caption mb-n1"
            >
              Now
            </div>

            <div
              v-translate="{ taktNumber: currentTakt.taktIndex }"
              translate-context="MTaktStatus view toolbar content"
              class="caption-xs"
            >
              Takt %{ taktNumber }
            </div>
          </div>

          <div
            class="mx-1"
          >
            <div
              class="caption font-weight-bold mb-n1"
            >
              {{ currentTakt.dayAndTimeFormatted }}
            </div>

            <div
              class="caption-xs"
            >
              ({{ currentTakt.datesFormatted }})
            </div>
          </div>
        </div>

        <div
          class="d-flex"
        >
          <!-- from? -->
          <div
            class="d-flex align-center"
          >
            <div
              v-translate
              translate-context="MTaktStatus view toolbar content (from date)"
              class="mr-4 caption"
            >
              From?
            </div>

            <div>
              <v-tooltip
                bottom
                content-class="pa-0"
                :disabled="!showDueTodos"
              >
                <template #activator="{ on: tooltip }">
                  <div
                    v-on="tooltip"
                  >
                    <v-input
                      dark
                      :disabled="showDueTodos"
                      class="secondary relative"
                      hide-details
                    >
                      <input
                        :disabled="showDueTodos"
                        :value="tactStart"
                        type="number"
                        :min="minTact"
                        :max="tactEnd"
                        class="secondary darken-2 text-center"
                        style="width: 48px;"
                        @blur="e => setTactStart(e.target.value)"
                      >

                      <div
                        v-if="showDueTodos"
                        :style="{
                          position: 'absolute',
                          top: '-1px',
                          right: '4px',
                          textAlign: 'right',
                        }"
                      >
                        <v-icon
                          small
                          color="secondary lighten-2"
                        >
                          info
                        </v-icon>
                      </div>
                    </v-input>
                  </div>
                </template>

                <v-card
                  max-width="300px"
                >
                  <v-card-text
                    v-translate="{ br: '<br>' }"
                    translate-context="MTaktStatus view toolbar content (show overdue tip)"
                    render-html="true"
                  >
                    When "Show overdue" is active, this value will be automatically
                    set to current takt and cannot be changed.
                    %{ br }
                    %{ br }
                    Untick "Show overdue" if you want to browse earlier takts.
                  </v-card-text>
                </v-card>
              </v-tooltip>
            </div>
          </div>

          <!-- reset -->
          <div
            class="no-print"
            dark
          >
            <v-btn
              small
              dark
              color="secondary darken-4"
              @click="setToday"
            >
              <span
                v-translate
                translate-context="MTaktStatus view toolbar content (today button)"
              >
                Today
              </span>
            </v-btn>
          </div>

          <!-- to? -->
          <div
            class="d-flex align-center"
          >
            <div>
              <v-input
                class="secondary"
                hide-details
                dark
              >
                <input
                  :value="tactEnd"
                  type="number"
                  :min="tactStart"
                  :max="maxTact"
                  class="secondary darken-2 text-center"
                  style="width: 48px;"
                  @blur="e => setTactEnd(e.target.value)"
                >
              </v-input>
            </div>

            <div
              v-translate
              translate-context="MTaktStatus view toolbar content (to date)"
              class="ml-4 caption"
            >
              To?
            </div>
          </div>
        </div>

        <!-- show due todos -->
        <div
          class="no-print"
        >
          <v-checkbox
            ref="showDueTodosRef"
            v-model="showDueTodos"
            hide-details
          >
            <template v-slot:label>
              <div
                v-translate
                translate-context="MTaktStatus view toolbar content"
                class="caption"
              >
                Show overdue
              </div>
            </template>
          </v-checkbox>
        </div>

        <!-- filter teams -->
        <m-team-filter />

        <v-menu
          v-model="printMenu"
          offset-y
          :close-on-content-click="false"
        >
          <template
            v-slot:activator="{ on, attrs }"
          >
            <v-btn
              v-bind="attrs"
              icon
              class="no-print"
              v-on="on"
            >
              <v-icon>
                print
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              v-translate
              translate-context="MTaktStatus view print card title"
            >
              Print this view (BETA)
            </v-card-title>

            <v-card-subtitle
              v-translate
              translate-context="MTaktStatus view print card subtitle"
            >
              Choose printing settings
            </v-card-subtitle>

            <v-card-text>
              <v-alert
                text
                dense
                type="warning"
                max-width="400px"
              >
                <template #default>
                  <div
                    v-translate
                    translate-context="MTaktStatus view print card content"
                    class="caption"
                  >
                    This feature is still in BETA stage, so unexpected
                    behaviour may occur.
                  </div>
                </template>
              </v-alert>

              <div>
                <ol>
                  <li
                    v-translate
                    translate-context="MTaktStatus view print card content"
                  >
                    Choose time period
                  </li>

                  <li
                    v-translate
                    translate-context="MTaktStatus view print card content"
                  >
                    Choose teams (all by default)
                  </li>

                  <li
                    v-translate
                    translate-context="MTaktStatus view print card content"
                  >
                    Select "Show overdue" if you want overdue tasks
                  </li>
                </ol>
              </div>

              <m-tip
                :tip="printingTipText"
                style="max-width: 400px;"
                class="mt-4"
              />

              <v-divider
                class="my-6"
              />

              <v-checkbox
                v-model="reduceColors"
                :label="printingReduceColorsLabel"
                :hint="printingReduceColorsHint"
                persistent-hint
                class="mt-0 pt-0"
              />
            </v-card-text>

            <v-card-actions
              class="px-4 pb-4"
            >
              <v-btn
                dark
                color="secondary"
                class="px-8"
                @click="openPrintWindow"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view print card button"
                >
                  Print
                </span>

                <v-icon
                  small
                  right
                >
                  launch
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </v-toolbar>

    <v-menu
      v-model="contextMenu.show"
      :position-x="contextMenu.x"
      :position-y="contextMenu.y"
      absolute
      offset-y
      :transition="false"
      max-height="500px"
      max-width="700px"
      class="mh-5"
    >
    <v-row class="backgroundAccent lighten-4" >
      <v-col>
        <div class="mt-3 ml-3">
          <h4>{{ selectedTodo.taskName }} ({{ selectedTodo.address }})</h4>
          <p class="text-caption mb-0" v-if="selectedTodo.taskDescription">{{ selectedTodo.taskDescription }}</p>
          <p class="text-caption mb-0" v-if="selectedTodo.comment">{{ selectedTodo.comment }}</p>
        </div>
        <v-list
          dense
        >
          <v-list-item
            @click="openTodoCommentDialog"
          >
            <v-list-item-icon
              class="mr-4"
            >
              <v-icon
                color="info"
              >
                info
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-translate
                translate-context="MTaktStatus view todo popup content"
              >
                Add additional details
              </v-list-item-title>

              <v-list-item-subtitle
                v-translate
                translate-context="MTaktStatus view todo popup content"
              >
                Task details and observations
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-subheader
            v-translate
            translate-context="MTaktStatus view todo popup section title"
            class="mt-2"
          >
            Task state
          </v-subheader>

          <v-list-item
            :class="{
              'menu-item': true,
              'menu-item-enabled': selectedTodo.canStart,
            }"
            @click="start"
          >
            <v-list-item-icon
              class="mr-4"
            >
              <v-icon
                color="warning"
              >
                outlined_flag
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-translate
                translate-context="MTaktStatus view todo popup content"
              >
                Begin
              </v-list-item-title>

              <v-list-item-subtitle
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup set started [1/2]"
                >
                  Sets task to state
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup set started [2/2]"
                  class="px-1 m-state-started"
                >Started</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item
            :class="{
              'menu-item': true,
              'menu-item-enabled': selectedTodo.canComplete,
            }"
            @click="complete"
          >
            <v-list-item-icon
              class="mr-4"
            >
              <v-icon
                color="secondary"
              >
                done_all
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ todoNeedsCheckupTitleText }}
              </v-list-item-title>

              <v-list-item-subtitle
                v-if="needsCheckup"
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup check up [1/2]"
                >
                  Check up task &amp; mark as
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup check up [2/2]"
                  class="px-1 m-state-completed"
                >Complete</span>
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup set complete [1/2]"
                >
                  Sets task to state
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup set complete [2/2]"
                  class="px-1 m-state-completed"
                >Complete</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item
            :class="{
              'menu-item': true,
              'menu-item-enabled': selectedTodo.canComplete || selectedTodo.canContinue,
            }"
            @click="() => setPreviousState(selectedTodo)"
          >
            <v-list-item-icon
              class="mr-4"
            >
              <v-icon>
                settings_backup_restore
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-translate
                translate-context="MTaktStatus view todo popup content"
              >
                Previous state
              </v-list-item-title>

              <v-list-item-subtitle
                v-if="selectedTodo.state === 'started'"
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup reset to can begin [1/2]"
                >
                  Sets task to state
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup reset to can begin [2/2]"
                  class="px-1 m-state-enabled"
                >Can begin</span>
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="selectedTodo.state === 'completed'"
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup reset to started [1/2]"
                >
                  Sets task to state
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup reset to started [2/2]"
                  class="px-1 m-state-started"
                >Started</span>
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup reset available in states [1/4]"
                >
                  Only states
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup reset available in states [2/4]"
                  class="px-1 m-state-started"
                >Started</span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup reset available in states [3/4]"
                >
                  or
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup reset available in states [4/4]"
                  class="px-1 m-state-completed"
                >Complete</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-subheader
            v-translate
            translate-context="MTaktStatus view todo popup section title"
            class="mt-2"
          >
            Interruption management
          </v-subheader>

          <v-list-item
            :class="{
              'menu-item': true,
              'menu-item-enabled': selectedTodo.canInterrupt,
            }"
            @click="openInterruptionDialog"
          >
            <v-list-item-icon
              class="mr-4"
            >
              <v-icon
                color="error"
              >
                warning
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-translate
                translate-context="MTaktStatus view todo popup content"
              >
                Interrupt
              </v-list-item-title>

              <v-list-item-subtitle
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup set interrupted [1/2]"
                >
                  Changes task state to
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup set interrupted [2/2]"
                  class="px-1 m-state-interrupted"
                >Interrupted</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item
            :class="{
              'menu-item': true,
              'menu-item-enabled': selectedTodo.canResolve,
            }"
            @click="resolve"
          >
            <v-list-item-icon
              class="mr-4"
            >
              <v-icon
                color="info"
              >
                play_arrow
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-translate
                translate-context="MTaktStatus view todo popup content"
              >
                Continue
              </v-list-item-title>

              <v-list-item-subtitle
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup unset interrupted [1/3]"
                >
                  Changes task state to
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup unset interrupted [2/3]"
                  class="px-1 m-state-enabled"
                >Can begin</span>.
                <br>
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup unset interrupted [3/3]"
                >
                  Used when the interruption has been solved.
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-subheader
            v-translate
            translate-context="MTaktStatus view todo popup section title"
            class="mt-2"
          >
            Exception management
          </v-subheader>

          <v-list-item
            :class="{
              'menu-item': true,
              'menu-item-enabled': selectedTodo.canSkip,
            }"
            @click="openSkipDialog"
          >
            <v-list-item-icon
              class="mr-4"
            >
              <v-icon>
                not_interested
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-translate
                translate-context="MTaktStatus view todo popup content"
              >
                Skipped
              </v-list-item-title>

              <v-list-item-subtitle
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup set skipped [1/3]"
                >
                  Task won't be carried out. Works
                </span>
                <br>
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup set skipped [2/3]"
                >
                  only in state
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup set skipped [3/3]"
                  class="px-1 m-state-enabled"
                >Can begin</span>.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item
            :class="{
              'menu-item': true,
              'menu-item-enabled': selectedTodo.canUnskip,
            }"
            @click="openUnskipDialog"
          >
            <v-list-item-icon
              class="mr-4"
            >
              <v-icon>
                lock_open
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-translate
                translate-context="MTaktStatus view todo popup content"
              >
                Open task
              </v-list-item-title>

              <v-list-item-subtitle
                class="caption"
              >
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup unset skipped [1/4]"
                >
                  Changes task state to
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup unset skipped [2/4]"
                  class="px-1 m-state-enabled"
                >Can begin</span>.
                <br>
                <span
                  v-translate
                  translate-context="MTaktStatus view todo popup unset skipped [3/4]"
                >
                  Used when task is in state
                </span> <span
                  v-translate
                  translate-context="MTaktStatus view todo popup unset skipped [4/4]"
                  class="px-1 m-state-skipped"
                >Skipped</span>.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col v-if="selectedTodo.comments && selectedTodo.comments.length > 0" style="min-width: 100px;">
        <div class="mt-3">
          <h5>
            Comments
          </h5>
          <template v-for="(comment, i) in selectedTodo.comments">
            <div class="pa-1 mb-3 mr-2 backgroundAccent">
              <span class="text-caption">
                {{ userById(comment.authorId).fullName }} - {{ $dayjs(comment.createdAt).fromNow() }}
              </span>
              <p>
                {{ comment.content }}
              </p>
              <template v-for = "(attachment, j) in comment.attachments">
                <a :href="attachment.url" target="_blank">
                 <img :src="attachment.url" width="100px" height="auto"/>
                </a>
              </template>
            </div>
          </template>
        </div>
      </v-col>
     </v-row>
    </v-menu>

    <m-loader-circular
      v-if="$wait.is('initial load - todos by teams')"
    />

    <div
      v-show="!$wait.is('initial load - todos by teams')"
      class="overflow-x-auto sticky-table-container"
    >
      <table
        ref="stickyTable"
        class="sticky-table"
      >
        <thead>
          <tr
            id="teamsRow"
          >
            <th />

            <th
              v-for="(team, i) in filteredTeams"
              :key="i"
              class="py-1 text-uppercase caption-xs"
            >
              {{ team.name }}
            </th>
          </tr>
        </thead>

        <tbody>
          <!-- due tacts -->
          <template
            v-if="showDueTodos"
          >
            <template
              v-for="(dueTact, dueTactI) in dueTacts"
            >
              <tr
                v-if="dueTodos[dueTact.taktIndex]"
                :key="`due-${dueTactI}`"
              >
                <th
                  class="caption-xs pa-2 errorBackground"
                >
                  <span
                    v-translate="{ taktNumber: dueTact.taktIndex, br: '<br>' }"
                    translate-context="MTaktStatus view table row header"
                    render-html="true"
                    class="d-block"
                    style="max-width: 76px;"
                  >
                    Takt %{ taktNumber }
                    %{ br }
                    (late)
                  </span>
                </th>

                <td
                  v-for="(team, teamI) in filteredTeams"
                  :key="`team-${teamI}`"
                >
                  <template
                    v-for="(todo, todoI) in dueTodos[dueTact.taktIndex]"
                  >
                    <m-todo-card-by-team
                      v-if="todo.teamId === team.id"
                      :key="`team-${teamI}-todo-${todoI}`"
                      :todo="todo"
                      :data-todo-id="todo.id"
                      class="todo-card-by-team"
                      :class="{
                        'reduce-colors': reduceColors,
                      }"
                    />
                  </template>
                </td>
              </tr>
            </template>
          </template>

          <template
            v-for="(tact, tactI) in selectedTacts"
          >
            <tr
              :key="tactI"
            >
              <th
                class="pa-2 selectedTacts text-left"
                :class="{
                  'warningBackground': currentTact.taktIndex > tact.taktIndex,
                  'successBackground': currentTact.taktIndex === tact.taktIndex,
                  'infoBackground': currentTact.taktIndex < tact.taktIndex,
                }"
              >
                <div
                  style="max-width: 76px; font-size: 8px;"
                >
                  <div>
                    {{ labels(tact).title }}
                  </div>

                  <div
                    class="mt-2"
                  >
                    <div
                      v-translate
                      translate-context="MTaktStatus takt start title"
                      class="text-uppercase"
                      style="font-size: 7px;"
                    >
                      Starts
                    </div>

                    <div
                      class="font-weight-bold"
                    >
                      {{ labels(tact).starts }}
                    </div>
                  </div>

                  <div
                    class="mt-2"
                  >
                    <div
                      v-translate
                      translate-context="MTaktStatus takt end title"
                      class="text-uppercase"
                      style="font-size: 7px;"
                    >
                      Ends
                    </div>

                    <div
                      class="font-weight-bold"
                    >
                      {{ labels(tact).ends }}
                    </div>
                  </div>
                </div>
              </th>

              <td
                v-for="(team, teamI) in filteredTeams"
                :key="`team-${teamI}`"
                class="teamTodosCol"
              >
                <template
                  v-for="(todo, todoI) in todos[tact.taktIndex]"
                >
                  <m-todo-card-by-team
                    v-if="todo.teamId === team.id"
                    :key="`team-${teamI}-todo-${todoI}`"
                    :todo="todo"
                    :data-todo-id="todo.id"
                    class="todo-card-by-team"
                    :class="{
                      'reduce-colors': reduceColors,
                    }"
                  />
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <v-overlay
      v-if="$wait.is('preparing print preview')"
    >
      <div
        class="mt-n12"
      >
        <m-loader-circular
          :text="preparingPrintPreviewText"
          class="mt-n12"
        />
      </div>
    </v-overlay>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import { poller } from '@/mixins';
  import MTodoCardByTeam from '@/components/MTodoCardByTeam';
  import MTeamFilter from '@/components/MTeamFilter';

  export default {
    components: {
      MTeamFilter,
      MTodoCardByTeam,
    },

    mixins: [
      poller,
    ],

    props: {
      _reduceColors: { // eslint-disable-line
        type: Boolean,
        default: true,
      },

      _showDueTodos: { // eslint-disable-line
        type: Boolean,
        default: false,
      },

      _selectedTeamIds: { // eslint-disable-line
        type: Array,
        default: () => [],
      },
    },

    data: () => ({
      initialDataLoaded: false,
      contextMenu: {
        show: false,
        x: null,
        y: null,
        todo: {},
      },
      printMenu: false,
      reduceColors: true,
    }),

    computed: {
      ...mapGetters({
        currentTact: 'project/tacts/currentTact',
        currentTakt: 'project/tacts/currentTakt',
        userById: 'users/userById',
        selectedTeams: 'project/tacts/selectedTeams',
        tactRange: 'project/tacts/tactRange',
        tacts: 'project/tacts/tacts',
        minTact: 'project/tacts/minTact',
        maxTact: 'project/tacts/maxTact',
        __tactStart__: 'project/tacts/tactStart',
        __tactEnd__: 'project/tacts/tactEnd',
        todos: 'project/todos/todosByTakt',
        __teams__: 'project/teams/teams',
        __showDueTodos__: 'project/todos/showDueTodos',
        filteredTeams: 'project/teams/selectedTeams',
        isMobile: 'utils/isMobile',
      }),

      tactStart: {
        get() {
          return this.__tactStart__;
        },

        set(newVal) {
          this.setTactStart(newVal);
        },
      },

      tactEnd: {
        get() {
          return this.__tactEnd__;
        },

        set(newVal) {
          this.setTactEnd(newVal);
        },
      },

      showDueTodos: {
        get() {
          return this.__showDueTodos__;
        },

        set(show) {
          this.setShowDueTodos(show);

          if (show) {
            this.tactStart = this.currentTact.taktIndex;
          }
        },
      },

      teams() {
        return this.__teams__.reduce((acc, team) => {
          acc.push({
            id: team.id,
            name: team.name,
          });

          return acc;
        }, []);
      },

      dueTacts() {
        if (this.currentTact.taktIndex <= 0) return [];

        const from = 0;
        const to = this.currentTact.taktIndex - 1;

        return this.tacts.slice(from, to);
      },

      dueTodos() {
        const dueTodos = {};

        this.dueTacts.forEach(({ taktIndex }) => {
          if (!this.todos[taktIndex]) return; // Skip

          this.todos[taktIndex].forEach((todo) => {
            if (!['completed', 'skipped'].includes(todo.state)) {
              if (!dueTodos[taktIndex]) dueTodos[taktIndex] = [];

              dueTodos[taktIndex].push(todo);
            }
          });
        });

        return dueTodos;
      },

      selectedTacts() {
        let from = this.tactRange[0];
        const to = this.tactRange[1];

        if (this.showDueTodos) {
          from = this.currentTact.taktIndex;
        }

        return this.tacts.slice(from - 1, to);
      },

      todosArray() {
        return Object.values(this.todos).flat();
      },

      selectedTodo() {
        return this.contextMenu.todo;
      },

      print() {
        return this.$route.name === 'takt-status-print';
      },

      needsCheckup() {
        const {
          hasCheckupItems,
          state,
        } = this.selectedTodo;

        return state === 'started' && hasCheckupItems;
      },

      printingTipText() {
        return this.$pgettext(
          'MTaktStatus view print card tip',
          `
            If the content doesn't seem to properly fit on the paper
            try another time period or choose a smaller set of teams.
          `,
        );
      },

      printingReduceColorsLabel() {
        return this.$pgettext(
          'MTaktStatus view print card checkbox label (reduce colors)',
          'Reduce color usage',
        );
      },

      printingReduceColorsHint() {
        return this.$pgettext(
          'MTaktStatus view print card checkbox hint (reduce colors)',
          'Save ink, save money, save the environment.',
        );
      },

      preparingPrintPreviewText() {
        return this.$pgettext(
          'MTaktStatus view print preview loader',
          'Preparing print preview...',
        );
      },

      todoNeedsCheckupTitleText() {
        if (this.needsCheckup) {
          return this.$pgettext('MTaktStatus view todo content', 'Check & Done');
        }

        return this.$pgettext('MTaktStatus view todo content', 'Done');
      },
    },

    watch: {
      tactStart(newVal, oldVal) {
        if (newVal === oldVal) return;
        if (!this.initialDataLoaded) return;

        this.getData();
      },

      tactEnd(newVal, oldVal) {
        if (newVal === oldVal) return;
        if (!this.initialDataLoaded) return;

        this.getData();
      },

      showDueTodos(newVal, oldVal) {
        if (newVal === oldVal) return;
        if (!this.initialDataLoaded) return;

        this.getData();
      },

      initialDataLoaded(loaded) {
        if (!loaded) return;
        if (this.print) this.doPrint();
      },
    },

    created() {
      if (this.print) this.prepareToPrint();
      this.loadInitialData();

      /**
       * The data request for this view is so large that let's make sure
       * we don't hang ourselves with too intensive polling. Default is
       * 5 seconds but let's make it 60 seconds for this view.
       */
      this.dataPollingInterval = 60000;
    },

    mounted() {
      this.addListeners();
    },

    beforeDestroy() {
      this.destroyListeners();
    },

    methods: {
      ...mapActions({
        loadTeams: 'project/teams/loadTeams',
        resetCurrentTact: 'project/tacts/resetCurrentTact',
        loadCurrentTact: 'project/tacts/loadCurrentTact',
        loadTacts: 'project/tacts/loadTacts',
        setTactStart: 'project/tacts/setTactStart',
        setTactEnd: 'project/tacts/setTactEnd',
        setShowDueTodos: 'project/todos/setShowDueTodos',
        loadUsers: 'users/loadUsers',
        openDialog: 'dialog/openDialog',
        unskipAssignment: 'project/todos/unskipAssignment',
        startAssignment: 'project/todos/startAssignment',
        completeAssignment: 'project/todos/completeAssignment',
        resolveAssignment: 'project/todos/resolveAssignment',
        setSelectedTeamIds: 'project/teams/setSelectedTeamIds',
        cancelAssignment: 'project/todos/cancelAssignment',
        continueAssignment: 'project/todos/continueAssignment',
        loadTodoComments: 'project/todos/loadTodoComments',
        commentTodo: 'project/todos/commentTodo',
      }),

      ...mapWaitingActions('project/todos', {
        loadTodos: {
          action: 'loadTodos',
          loader: 'initial load - todos by teams',
        },
        reloadTodos: {
          action: 'loadTodos',
          loader: 'reloading load - todos by teams',
        },
      }),

      async loadInitialData() {
        this.$wait.start('initial load - todos by teams');

        this.resetCurrentTact(); // Hack to force data load through the poller
        this.setTactStart();
        this.setTactEnd();

        await Promise.all([
          this.loadTeams(),
          this.loadTacts(),
          this.loadCurrentTact(),
          this.loadUsers({ projectId: this.$projectId, groupBy: 'id' }),
        ]);
      },

      /**
       * This is called by the poller on currentTact change/update.
       */
      async getData() {
        const method = this.initialDataLoaded ? 'reloadTodos' : 'loadTodos';

        await this[method]({
          // May reduce payload size significantly
          columns: [
            'id',
            'activeInterruptionComment',
            'activeInterruptionHumanReadableReason',
            'address',
            'comment',
            'commentOnSkip',
            'currentOwnerId',
            'dependingTodo',
            'estimatedDuration',
            'hasCheckupItems',
            'humanReadableState',
            'requiresSupervision',
            'state',
            'takt',
            'taskId',
            'taskName',
            'teamId',
            'teamName',
            'taskDescription',

            'canStart',
            'canComplete',
            'canContinue',
            'canInterrupt',
            'canResolve',
            'canSkip',
            'canUnskip',
          ],
          taktFrom: this.showDueTodos ? 1 : this.tactStart,
          taktTo: this.tactEnd,
        });

        this.initialDataLoaded = true;
      },

      /**
       * This is called by the poller.
       */
      getNightly() {
        this.loadCurrentTact();
      },

      labels({
        startsAt,
        workdayEndsAt,
        taktIndex,
      }) {
        const starts = this.$dayjs(startsAt);
        const ends = this.$dayjs(workdayEndsAt);
        const dateFormat = 'ddd / DD.MM. / HH:mm';
        const currentTakt = this.currentTact.taktIndex;

        let title;

        const past = this.$gettextInterpolate(
          this.$pgettext(
            'MTaktStatus view takt title',
            'Takt %{ taktIndex } (past)',
          ),
          { taktIndex },
        );
        const now = this.$gettextInterpolate(
          this.$pgettext(
            'MTaktStatus view takt title',
            'Takt %{ taktIndex } (now)',
          ),
          { taktIndex },
        );
        const upcoming = this.$gettextInterpolate(
          this.$pgettext(
            'MTaktStatus view takt title',
            'Takt %{ taktIndex } (upcoming)',
          ),
          { taktIndex },
        );

        const titleOptions = {
          past,
          now,
          upcoming,
        };

        if (taktIndex < currentTakt) {
          title = titleOptions.past;
        } else if (taktIndex === currentTakt) {
          title = titleOptions.now;
        } else if (taktIndex > currentTakt) {
          title = titleOptions.upcoming;
        }

        return {
          title,
          starts: starts.format(dateFormat),
          ends: ends.format(dateFormat),
        };
      },

      setToday() {
        this.setTactStart(this.currentTact.taktIndex);
        this.setTactEnd(this.currentTact.taktIndex);
      },

      addListeners() {
        this.$refs.stickyTable.addEventListener('mouseover', this.onCardMouseOver, { passive: true });
        this.$refs.stickyTable.addEventListener('click', this.onCardMouseClick, { passive: true });
      },

      destroyListeners() {
        this.$refs.stickyTable.removeEventListener('mouseover', this.onCardMouseOver);
        this.$refs.stickyTable.removeEventListener('click', this.onCardMouseClick);
      },

      onCardMouseOver(e) {
        document
          .querySelectorAll('.todo-card-by-team.highlight')
          .forEach(el => el.classList.remove('highlight'));

        if (!e.target.classList.contains('todo-card-by-team')) return;

        e.target.classList.add('highlight');
      },

      onCardMouseClick(e) {
        if (!e.target.classList.contains('todo-card-by-team')) return;

        /**
         * Reset and close it
         */
        if (this.contextMenu.show) {
          this.contextMenu.show = false;
          this.contextMenu.x = null;
          this.contextMenu.y = null;
          this.contextMenu.todo = {};
          return;
        }

        this.$mixpanel.trackEvent('Open Todo Context Menu', {
          view: 'MTaktStatus',
          position: 'ContextMenu',
        });

        const todoId = +e.target.dataset.todoId;
        const todo = this.todosArray.find(t => t.id === todoId);

        const {
          top: y,
          right: x,
        } = e.target.getBoundingClientRect();

        this.loadTodoComments({todoId: todo.id});
        this.contextMenu.x = x;
        this.contextMenu.y = y;
        this.contextMenu.todo = todo;
        this.contextMenu.show = true;
      },

      openTodoCommentDialog() {
        this.$mixpanel.trackEvent('Open Comment Dialog', {
          view: 'MTaktStatus',
          position: 'ContextMenu',
        });

        this.openDialog({
          dialogComponent: 'm-todo-comment-dialog',
          dialogProps: {
            todo: this.contextMenu.todo,
          },
          config: {
            fullscreen: this.isMobile,
          },
        });
      },

      openSkipDialog() {
        this.$mixpanel.trackEvent('Open Skip Dialog', {
          view: 'MTaktStatus',
          position: 'ContextMenu',
        });

        this.openDialog({
          dialogComponent: 'm-skip-todo-dialog',
          dialogProps: {
            todo: this.contextMenu.todo,
          },
          config: {
            fullscreen: this.isMobile,
          },
        });
      },

      openInterruptionDialog() {
        this.$mixpanel.trackEvent('Open Interruption Dialog', {
          view: 'MTaktStatus',
          position: 'ContextMenu',
        });

        this.openDialog({
          dialogComponent: 'm-interrupt-todo-dialog',
          dialogProps: {
            todo: this.contextMenu.todo,
          },
          config: {
            fullscreen: this.isMobile,
          },
        });
      },

      openUnskipDialog() {
        this.$mixpanel.trackEvent('Open Unskip Dialog', {
          view: 'MTaktStatus',
          position: 'ContextMenu',
        });

        this.openDialog({
          dialogComponent: 'm-confirm-base-dialog',
          dialogProps: {
            confirm: () => {
              this.unskipAssignment({
                id: this.contextMenu.todo.id,
              });
            },
            prompt: this.$pgettext('MTaktStatus view open dialog prompt', 'Should the task be done?'),
            icon: 'thumb_up',
            iconColor: 'success',
          },
        });
      },

      start() {
        this.$mixpanel.trackEvent('Start Todo', {
          view: 'MTaktStatus',
          position: 'ContextMenu',
        });

        this.startAssignment({
          id: this.contextMenu.todo.id,
        });
      },

      complete() {
        if (this.needsCheckup) {
          this.openDialog({
            dialogComponent: 'm-checkup-dialog',
            dialogProps: {
              todo: this.selectedTodo,
            },
            config: {
              fullscreen: this.isMobile,
              scrollable: true,
            },
          });
        } else {
          this.completeAssignment({
            id: this.contextMenu.todo.id,
          });
        }
      },

      resolve() {
        this.$mixpanel.trackEvent('Resolve Todo', {
          view: 'MTaktStatus',
          position: 'ContextMenu',
        });

        this.resolveAssignment({
          id: this.contextMenu.todo.id,
        });
      },

      prepareToPrint() {
        this.$wait.start('preparing print preview');
        this.reduceColors = this._reduceColors;
        this.showDueTodos = this._showDueTodos;
        this.setSelectedTeamIds(this._selectedTeamIds);
      },

      openPrintWindow() {
        this.printMenu = false;
        let selectedTeamIds = '';
        let url = `${document.URL}/print?showDueTodos=${this.showDueTodos}&reduceColors=${this.reduceColors}`;

        if (this.filteredTeams.length < this.teams.length) {
          selectedTeamIds = this.filteredTeams
            .map(t => t.id)
            .join(',');

          url += `&selectedTeamIds=${selectedTeamIds}`;
        }

        window.open(url, '_blank', 'status=yes');
      },

      doPrint() {
        this.$mixpanel.trackEvent('Print MTaktStatus', {
          reduceColors: this.reduceColors,
          fromTakt: this.tactStart,
          toTakt: this.tactEnd,
          selectedTeamsCount: this.filteredTeams.length,
          teamsCount: this.teams.length,
          selectedAllTeams: this.filteredTeams.length === this.teams.length,
          projectId: this.$projectId,
        });

        /**
         * Allows the UI set before opening the browser's print window
         */
        setTimeout(() => {
          this.$wait.end('preparing print preview');

          this.$nextTick(() => {
            window.print();
          });
        }, 1000);
      },

      async setPreviousState({ id, state }) {
        // eslint-disable-next-line
        const confirmed = confirm(
          this.$pgettext(
            'MTaktStatus view confirm reverting state',
            'Would you like to revert this task to its previous state?',
          ),
        );

        if (!confirmed) return;

        if (state === 'started') {
          await this.cancelAssignment({
            id,
          });
        }

        if (state === 'completed') {
          await this.continueAssignment({
            id,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sticky-table-container {
    max-height: calc(100vh - 96px);
  }

  .sticky-table {
    position: relative;
    border-collapse: collapse;

    td {
      padding: 4px 2px;
      vertical-align: top;
    }

    th {
      .cell {
        width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0 auto;
      }
    }

    thead th {
      position: sticky;
      top: 0;
      background: #424242;
      color: #FFF;
      z-index: 1;
    }

    thead th:first-child {
      z-index: 2;
    }

    tbody th {
      vertical-align: top;
      position: sticky;
      left: 0;
      background: #FFF;
      border-right: 1px solid #CCC;
    }

    tbody tr:not(:first-child) th,
    tbody tr:not(:first-child) td {
      border-top: 2px dashed rgba(0, 0, 0, 0.25) !important;
    }

    .highlight {
      position: relative;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }

  .space-container {
    min-width: 100px;
  }

  .menu-item {
    pointer-events: none;
    opacity: 0.4;

    &.menu-item-enabled {
      pointer-events: initial;
      opacity: 1;
    }
  }
</style>

<style lang="scss">
@media print {
  @page {
    size: landscape;
    margin: 5mm;
  }

  #app {
    background-color: transparent;
  }

  .m-takt-status {
    zoom: 50%;
  }

  .sticky-table-container {
    max-height: none !important;
  }

  .sticky-table tr {
    page-break-after: always;
  }
}
</style>
