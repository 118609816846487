import { mapGetters, mapActions } from 'vuex';

export const poller = { // eslint-disable-line
  data: () => ({
    pollers: [],
    timeouts: [],
    dataPollingInterval: 15000,
  }),

  computed: {
    ...mapGetters({
      isDemoEnv: 'utils/isDemoEnv',
      isDevEnv: 'utils/isDevEnv',
      currentTact: 'project/tacts/currentTact',
    }),

    msToNightlyUpdate() {
      const now = new Date();
      const night = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1, // the next day, ...
        3, 0, 0, // ...at 03:00:00 hours
      );

      const msToMidnight = night.getTime() - now.getTime();

      return msToMidnight;
    },
  },

  watch: {
    currentTact(newVal, oldVal) {
      if (!this.getData) return;
      if (newVal.taktIndex === oldVal.taktIndex) return;
      if (Object.values(newVal).length <= 0) return;

      this.getData();
    },
  },

  beforeDestroy() {
    this.resetPollers();
    this.resetTimeouts();
  },

  mounted() {
    this.pollData();
    this.pollNightly();
  },

  methods: {
    ...mapActions({
      loadCurrentTact: 'project/tacts/loadCurrentTact',
    }),

    pollData() {
      if (!this.getData) return;
      if (this.isDevEnv) return;

      const p = setInterval(this.getData, this.dataPollingInterval);

      this.pollers.push(p);
    },

    pollNightly() {
      if (!this.getNightly) return;
      if (this.isDevEnv) return;

      const timeout = setTimeout(() => {
        this.resetTimeouts();
        this.getNightly(); // <- Client method
        this.pollNightly(); // <- Restart timeout
      }, this.msToNightlyUpdate);

      this.timeouts.push(timeout);
    },

    resetPollers() {
      this.pollers.forEach(p => clearInterval(p));
      this.pollers = [];
    },

    resetTimeouts() {
      this.timeouts.forEach(t => clearTimeout(t));
      this.timeouts = [];
    },
  },
};
