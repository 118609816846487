<template>
  <div>
    <v-select
      v-model="computedSelectedTeams"
      :items="teams"
      :label="filterTeamsPlaceholder"
      outlined
      hide-details
      dense
      multiple
      clearable
      item-text="name"
      item-value="id"
      style="width: 340px; max-width: 100%;"
      :menu-props="{ offsetY: true }"
      class="no-print"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip
          v-if="index < 2"
          x-small
          color="white secondary--text text--darken-2"
        >
          {{ item.name }}
        </v-chip>

        <span
          v-if="index === 2"
          v-translate="{ teamsCount: (computedSelectedTeams.length - 2) }"
          translate-context="MTeamFilter component filtered teams not visible placeholder"
          class="white--text caption"
        >(+%{ teamsCount } more)</span>
      </template>
    </v-select>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        teams: 'project/teams/teams',
        selectedTeams: 'project/teams/selectedTeams',
        selectedTeamIds: 'project/teams/selectedTeamIds',
      }),

      computedSelectedTeams: {
        get() {
          if (this.selectedTeamIds.length <= 0) return [];
          return this.selectedTeams;
        },

        set(newVal) {
          this.setSelectedTeamIds(newVal);
        },
      },

      filterTeamsPlaceholder() {
        return this.$pgettext('MTeamFilter component select input placeholder', 'Filter teams');
      },
    },

    methods: {
      ...mapActions({
        setSelectedTeamIds: 'project/teams/setSelectedTeamIds',
      }),
    },
  };
</script>
