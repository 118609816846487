<template>
  <div
    :class="`
      todo-container
      px-2
      pb-1
      mb-1
      m-state-${state}
    `"
  >
    <div
      style="pointer-events: none;"
    >
      <div class="text-uppercase font-weight-bold caption-xs d-flex flex-wrap">
        <span>{{ todo.address }}</span>
        <span class="pe-1">&#x2022; {{ estimatedDuration }}</span>
        <template v-if="todo.requiresSupervision">
          <div class="d-flex align-center">
            <v-icon x-small class="pe-1" color="warning">warning</v-icon> 
            <span v-translate translate-context="MTodoCardByTeam component content">
              Pace-setting
            </span>
          </div>
        </template>
      </div>
      <div class="text-uppercase font-weight-bold caption super-dense-text py-1">
        {{ todo.taskName }}
      </div>

      <!-- Assignment info -->
      <div>
        <div class="d-flex flex-wrap">
          <div class="caption-xxs pe-1">
            {{ assigneeFullName }}
          </div>
          <div class="font-weight-bold caption-xxs">
            {{ humanReadableState }}
            <template v-if="todo.activeInterruptionHumanReadableReason">
              ({{ todo.activeInterruptionHumanReadableReason }})
            </template>
          </div>
        </div>


        <div
          v-if="todo.comment"
          class="font-italic caption-xxs"
        >
          <v-icon
            x-small
          >
            info
          </v-icon> {{ todo.comment }}
        </div>



        <div
          v-if="todo.activeInterruptionComment"
          class="font-italic caption-xxs"
        >
          {{ todo.activeInterruptionComment }}
        </div>

        <div
          v-if="todo.commentOnSkip"
          class="font-italic caption-xxs"
        >
          {{ todo.commentOnSkip }}
        </div>

        <div
          v-if="todo.state === 'waiting' && todo.dependingTodo"
          class="caption-xxs text-uppercase relative slider-container"
        >
          <div
            class="content"
          >
            <span
              v-translate
              translate-context="MTodoCardByTeam component content"
            >
              Waiting for
            </span>
            {{ todo.dependingTodo.address }} &#x2022; {{ todo.dependingTodo.taskName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      todo: {
        type: Object,
        required: true,
      },
    },

    computed: {
      ...mapGetters({
        userFullName: 'users/userFullName',
      }),

      state() {
        return this.todo.state;
      },

      interrupted() {
        return this.state === 'interrupted';
      },

      enabled() {
        return this.state === 'enabled';
      },

      disabled() {
        return this.state === 'disabled';
      },

      humanReadableState() {
        if (this.disabled) return this.$pgettext('MTodoCardByTeam component content', 'Can begin');

        return this.todo.humanReadableState;
      },

      assigneeFullName() {
        return this.userFullName(this.todo.currentOwnerId);
      },

      estimatedDuration() {
        const d = Math.round((this.todo.estimatedDuration / 60) * 10) / 10;

        return `${d} h`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .todo-container {
    word-break: break-all;
    border-bottom: 1px dashed rgba(#32384c, 0.8);
    min-width: 120px;

    &:last-child {
      border-color: transparent;
    }
  }

  @media print {
    .todo-container {
      min-width: 0 !important;
    }
  }
</style>
